@import '../../constants/ColorStyles.scss';

.sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  flex-direction: column;
  right: 0;
  height: 100vh;
  width: 70%;
  background-color: $white;
  transition: all 0.35s ease-out;
  z-index: 5;
  box-shadow: inset 0 0 0 2px rgb(255, 255, 255), -7px 0px 7px 1px rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: 450px) {
    width: 100%;
  }
  .burger-menu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 50px;
    margin-top: 5px;
    .hamburger-react {
      margin-right: 20px;
    }
  }
  .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 40px;
    width: 100%;
    .nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 33px;
      .nav-item {
        width: auto;
        height: 20px;
        font-size: 20px;
        font-weight: 400;
        margin-right: 9px;
        color: $red;
        text-decoration: none;
        margin-top: 15px;
        @media only screen and (max-width: 768px) {
          text-align: start;
          margin-right: 0px;
        }
      }
      .activeClass {
        font-weight: bold;
        width: auto;
        padding-bottom: 5px;
        border-bottom-style: solid;
      }
    }
  }
}
