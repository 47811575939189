@import '../../constants/ColorStyles.scss';

.accommodation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .spinner{
    margin-top: 35vh;
    z-index: 10;
    color: white;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 80%;
    .title {
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 50px;
      font-weight: 700;
      color: $red;
    }
    .subtitle {
      text-align: start;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
      color: $dark-blue;
    }
    .text {
      margin-top: 25px;
      text-align: justify;
      text-justify: inter-word;
      color: $dark-blue;
    }
    .apartman-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .single-row-container {
        width: 100%;
        .single-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height: 400px;
          margin-top: 40px;
          @media only screen and (max-width: 992px) {
            height: 320px;
          }
          @media only screen and (max-width: 768px) {
            flex-direction: column;
            height: auto;
          }
          .img-container {
            width: 50%;
            height: 380px;
            overflow: hidden;
            overflow-x: hidden;
            @media only screen and (max-width: 992px) {
              height: 300px;
            }
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            @media only screen and (max-width: 450px) {
              height: 230px;
            }
            .img {
              margin-left: -6px;
              width: 102%;
              height: 400px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
              &:hover {
                width: 102%;
                transform: scale(1.1);
              }
              @media only screen and (max-width: 992px) {
                height: 320px;
              }
              @media only screen and (max-width: 450px) {
                height: 250px;
              }
            }
          }
          .description-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;
            height: 100%;
            padding-left: 20px;
            @media only screen and (max-width: 768px) {
              width: 100%;
              padding-left: 4px;
            }
            .apartman-title {
              text-align: start;
              margin-top: 0px;
              margin-bottom: 0px;
              font-size: 20px;
              font-weight: 700;
              color: $red;
              @media only screen and (max-width: 992px) {
                font-size: 18px;
              }
              @media only screen and (max-width: 768px) {
                margin-top: 10px;
                font-size: 20px;
              }
            }
            .apartman-subtitle {
              margin-top: 0px;
              color: $red;
              @media only screen and (max-width: 768px) {
                margin-top: 0px;
                margin-bottom: 0px;
              }
            }
            .apartman-text {
              margin-top: 0px;
              margin-bottom: 0px;
              color: $red;
              text-align: justify;
              text-justify: inter-word;
              @media only screen and (max-width: 992px) {
                font-size: 13px;
              }
              @media only screen and (max-width: 768px) {
                font-size: 15px;
                width: 97%;
              }
            }
            .margin-big {
              margin-top: 40px;
            }
            .margin-small {
              margin-top: 20px;
            }
            .icons-container {
              position: absolute;
              bottom: 10%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: wrap;
              @media only screen and (max-width: 768px) {
                position: relative;
                margin-top: 40px;
              }
              .icon {
                color: $dark-blue;
                margin-right: 15px;
                width: 35px;
                height: 35px;
                margin-top: 5px;
                @media only screen and (max-width: 992px) {
                  height: 25px;
                  width: 25px;
                }
              }
            }
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 40px;
      width: 100%;
      .btn {
        text-decoration: none;
        cursor: pointer;
        background-color: $red;
        border-radius: 7px;
        width: 120px;
        padding-top: 7px;
        padding-bottom: 7px;
        border: 2px solid $red;
        color: $white;
        font-weight: 600;
        &:hover {
          background-color: transparent;
          border: 2px solid $red;
          color: $red;
          font-weight: 600;
        }
      }
    }
  }
  .btn-top {
    cursor: pointer;
    position: fixed;
    bottom: 8%;
    right: 2%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background: $red;
    .icon {
      font-size: 22px;
    }
  }
}
