@import '../../constants/ColorStyles.scss';

.single-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    .title {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 50px;
      font-weight: 700;
      color: $dark-blue;
    }
    .subtitle {
      text-align: start;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 700;
      color: $red;
    }
    .text {
      text-align: justify;
      text-justify: inter-word;
      width: 100%;
      margin-top: 0px;
      color: $dark-blue;
    }
    .image-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      .image-wrapper {
        width: 23.5%;
        min-width: 250px;
        height: 250px;
        margin: 10px;
        overflow: hidden;
        @media only screen and (max-width: 1700px) {
          width: 31%;
        }
        @media only screen and (max-width: 1100px) {
          width: 46%;
          min-width: none;
        }
        @media only screen and (max-width: 700px) {
          width: 100%;
          height: 300px;
        }
        .img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
          &:hover {
            width: 100%;
            transform: scale(1.1);
            
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      .btn {
        cursor: pointer;
        color: $red;
        border: none;
        font-size: 18px;
        text-decoration: underline;
        &:hover {
          color: $dark-blue;
        }
      }
    }
    .btn-container-down {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-top: 20px;
      .btn-back {
        cursor: pointer;
        color: $white;
        width: 150px;
        border-radius: 7px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: $dark-blue;
        border: 2px solid $dark-blue;
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        &:hover {
          background-color: transparent;
          border: 2px solid $dark-blue;
          color: $dark-blue;
          font-weight: 600;
        }
      }
    }
  }
}
