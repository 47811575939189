.overlay {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: black;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;
    transition: opacity 0.15s linear;
    .icon-container{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 20px;
        margin-right: 40px;
        width: 200px;
        height: 50px;
        .close-icon{
            cursor: pointer;
            font-size: 25px;
        }
    }
}