@import '../../constants/ColorStyles.scss';

.participants{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .btn-top{
        cursor: pointer;
        position: fixed;
        bottom: 8%;
        right: 2%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: none;
        background: $red;
        .icon{
          font-size: 22px;
        }
      }
      .participants-gallery{
        text-align: start;
      }
}