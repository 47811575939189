@import '../../constants/ColorStyles.scss';

.image-modal {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50% !important;
  max-width: 900px;
  min-width: 800px !important;
  max-height: 85% !important;
  transform: translate(-50%, -50%);
  z-index: 1000 !important;
  @media only screen and (max-width: 950px) {
    width: 100% !important;
    max-width: none !important;
    min-width: 0 !important;
  }
  .left-btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -40%;
    border: none;
    background-color: transparent;
    .icon {
      color: $white;
      font-size: 30px;
    }
    @media only screen and (max-width: 1600px) {
      left: -20%;
    }
    @media only screen and (max-width: 1280px) {
      left: -5%;
    }
    @media only screen and (max-width: 950px) {
      left: 2%;
      width: 50px;
      height: 50px;
      background-color: black;
      opacity: 0.6;
    }
  }
  .img {
    width: 100%;
  }
  .right-btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: -40%;
    border: none;
    background-color: transparent;
    .icon {
      color: $white;
      font-size: 30px;
    }
    @media only screen and (max-width: 1600px) {
      right: -20%;
    }
    @media only screen and (max-width: 1280px) {
      right: -5%;
    }
    @media only screen and (max-width: 950px) {
      right: 2%;
      width: 50px;
      height: 50px;
      background-color: black;
      opacity: 0.6;
    }
  }
}
