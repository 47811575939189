@import '../../constants/ColorStyles.scss';

.border-mobile {
  border-bottom: 2px solid #C7363D;
}
.mobile-header-small {
  display: none;
  width: 100%;
  background: white;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
  .inner-container {
      width: 100%;
      height: 100%;
    .navigation {
      display: flex;
      height: 60px;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0px;
      .image{
        margin-left: 20px;
        height: 70%;
        margin-top: 8px;
        width: 100px;
      }
      .burger-menu{
        margin-right: 20px;
        margin-top: 4px;
      }
    }
    .context {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 20px;
      .title {
        margin-top: 0px;
        font-size: 30px;
        color: $white;
        font-weight: bold;
      }
      .sub-title {
        width: 80%;
        margin-top: 0px;
        font-size: 24px;
        color: $white;
        margin-bottom: 40px;
        font-weight: 500;
      }
    }
  }
}
