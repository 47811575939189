@import '../../constants/ColorStyles.scss';

.sign-up-form {
  width: 80%;
  margin-bottom: 70px;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  .switch-box {
    width: 100%;
    // padding-top: 20px;
    // padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 800px) {
      padding-bottom: 0px;
    }
    .inner {
      width: 60%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media only screen and (max-width: 650px) {
        width: 100%;
      }
      .btn-legal-entity {
        background-color: white;
        border: 2px solid $red;
        margin: 20px;
        border-radius: 6px;
        cursor: pointer;
        .text {
          padding: 15px;
          margin: 0px !important;
          font-size: 36px;
          font-weight: 700;
          color: $red;
          @media only screen and (max-width: 1000px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 450px) {
            font-size: 15px;
          }
          @media only screen and (max-width: 350px) {
            font-size: 12px;
          }
        }
      }
      .btn-individual {
        background-color: white;
        border: 2px solid $red;
        margin: 20px;
        border-radius: 6px;
        cursor: pointer;
        background-color: $white;
        .text {
          padding: 15px;
          margin: 0px !important;
          font-size: 36px;
          font-weight: 700;
          color: $red;
          @media only screen and (max-width: 1000px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 450px) {
            font-size: 15px;
          }
          @media only screen and (max-width: 350px) {
            font-size: 12px;
          }
        }
      }
      .active {
        background-color: $red;
        border: 1px solid $red;
        .text {
          color: $white;
        }
      }
    }
  }
  .text-blue {
    color: $dark-blue;
  }
  .info-text {
    font-size: 50px;
    font-weight: 700;
    color: $red;
  }
  .noVisibility {
    display: none;
  }
  .img {
    width: 100%;
    min-height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    background-image: url('../../assets/show-accommodation-price.png');
    @media only screen and (max-width: 1000px) {
      min-height: 400px;
      background-image: url('../../assets/show-accommodation-price-tablet.png');
    }
    @media only screen and (max-width: 450px) {
      min-height: 400px;
      background-image: url('../../assets/show-accommodation-price-mobile.png');
    }
  }
  .agenda-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .title {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-left: 0px;
    color: $dark-blue;
  }
  .sub-title {
    width: 100%;
    text-align: center;
    margin-left: 0px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
    color: $red;
  }
}
