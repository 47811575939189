@import '../../constants/ColorStyles.scss';

.agenda-navigation-mobile {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: none;
  width: 89%;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
  .title {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-left: 0px;
    color: $dark-blue;
  }
  .sub-title {
    width: 100%;
    text-align: center;
    margin-left: 0px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
    color: $red;
  }
  .description {
    width: 100%;
    display: flex;
    justify-content: center;
    .description-title {
      color: $dark-blue;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .accordion-box-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .row {
      width: 100%;
      .accordion-input {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .accordion-item-mobile {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          border: 3px solid $dark-blue;
          margin-bottom: 20px;
          color: $dark-blue;
          .title {
            width: 85px;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .accordion-item-date {
            font-size: 20px;
            margin-left: 7px;
          }
        }
        .active-mobile {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          border: 3px solid $dark-blue;
          margin-bottom: 20px;
          background-color: $dark-blue;
          color: $white;
          margin-bottom: 0px;
          .title {
            width: 85px;
            font-size: 20px;
            font-weight: bold;
            color: $white;
            margin-bottom: 20px;
          }
          .accordion-item-date {
            color: $white;
            font-size: 20px;
            margin-left: 7px;
          }
        }
      }
    }
    .accordion-body {
      width: 100%;
      border: 3px solid $white;
      .blue-text {
        color: $dark-blue;
      }
      .data-title {
        display: flex;
        align-items: center;
        text-align: start;
        width: auto;
        font-size: 20px;
        padding: 5px;
        font-weight: 700;
        color: $white;
        background: $red-blue;
        margin-top: 2px;
        margin-bottom: 2px;
        .data-title-text {
          margin-left: 10px;
          p {
            margin-top: 3px;
            margin-bottom: 3px;
          }
        }
      }
      .data-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        .left-container {
          max-width: auto;
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-right: 10px;
          .img {
            height: 15px;
            margin-top: 13px;
            margin-right: 5px;
            margin-left: 5px;
          }
          .time {
            text-align: center;
            color: $dark-blue;
            margin-top: 10px;
            margin-right: 10px;
            font-size: 17px;
            min-width: 45px;
          }
          .text {
            text-align: start;
            color: $dark-blue;
            margin-top: 10px;
            margin-right: 10px;
            font-size: 17px;
            p {
              margin-top: 0px;
              margin-bottom: 7px;
            }
          }
          .company {
            font-weight: 700;
            color: $dark-blue;
            font-size: 19px;
          }
        }
      }
    }
    .close {
      display: none;
    }
  }
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .btn {
      font-size: 20px;
      border: none;
      color: $red;
      background-color: transparent;
      text-decoration: underline;
      margin-top: 0px;
      margin-bottom: 20px;
    }
    .agenda-container {
      display: none;
    }
  }
}
