@import '../../constants/ColorStyles.scss';

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // .temporary-container{
  //   width: 80%;
  //   .link{
  //     margin-left: 8px;
  //     color: $red;
  //   }
  //   .text{
  //     margin-top: 0px;
  //     font-size: 24px;
  //     font-weight: 700;
  //     color: $dark-blue;
  //     line-height: 50px;
  //     text-align: center;
  //     text-justify: inter-word;
  //     @media only screen and (max-width: 800px) {
  //       font-size: 20px;
  //     }
  //     @media only screen and (max-width: 650px) {
  //       font-size: 18px;
  //       line-height: 30px;
  //     }
  //   }
  // }
  .banner-btn-container {
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      display: flex;
    }
    .btn {
      cursor: pointer;
      border: none;
      margin-top: 0px;
      width: 90%;
      padding-top: 7px;
      padding-bottom: 7px;
      text-decoration: none;
      border-radius: 7px;
      background-color: $red;
      color: $white;
      font-size: 18px;
    }
  }
  .main-agenda {
    margin-top: 30px;
    text-align: center;
  }
  .main-agenda-subtitle{
    text-align: center;
  }
  .organizer-main {
    margin-top: 0px;
    text-align: center;
  }
  .main-gallery {
    text-align: center;
    margin-top: 20px;
  }
  .btn-top {
    cursor: pointer;
    position: fixed;
    bottom: 8%;
    right: 2%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background: $red;
    .icon {
      font-size: 22px;
    }
  }
}
