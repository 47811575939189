@import '../../constants/ColorStyles.scss';

.agenda{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .agenda-page{
        margin-top: 10px;
        text-align: start;
      }
    .agenda-page-subtitle{
      text-align: start;
    }
    .btn-top{
        cursor: pointer;
        position: fixed;
        bottom: 8%;
        right: 2%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: none;
        background: $red;
        .icon{
          font-size: 22px;
        }
      }
}