@import '../../constants/ColorStyles.scss';

.venue {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 70px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  .left-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    height: 100%;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
    .title {
      text-align: start;
      margin-top: 20px;
      margin-right: 10px;
      font-size: 50px;
      font-weight: 700;
      color: $red;
      margin-bottom: 0px;
      padding-right: 30px;
      @media only screen and (max-width: 768px) {
        font-size: 30px;
        margin-top: 0px;
      }
    }
    .sub-title {
      text-align: start;
      font-size: 20px;
      font-weight: 700;
      color: $dark-blue;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-right: 30px;
      @media only screen and (max-width: 768px) {
        margin-top: 0px;
        font-size: 25px;
      }
    }
    .text {
      width: 90%;
      color: $dark-blue;
      text-align: justify;
      text-justify: inter-word;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    .btn-container {
      display: flex;
      align-items: flex-start;
      width: 80%;
      margin-top: 30px;
      @media only screen and (max-width: 992px) {
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 30px;
      }
      @media only screen and (max-width: 768px) {
        display: none;
      }
      .btn {
        color: $red;
        font-weight: 400;
      }
    }
  }
  .right-container {
    width: 50%;
    height: 100%;
    overflow: hidden;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
    .image-wrapper {
      width: 100%;
      height: 480px;
      @media only screen and (max-width: 1200px) {
        height: 430px;
      }
      @media only screen and (max-width: 600px) {
        height: 380px;
      }
      @media only screen and (max-width: 450px) {
        height: 280px;
      }
      .video {
        height:100%; /* or is max-height:100%; */
        width:100%;  /* or is max-width:100%;  */
        object-fit: contain;
        object-position:center;
        transform: scale(1.7);
        @media only screen and (max-width: 1200px) {
          height: 450px;
        }
        @media only screen and (max-width: 600px) {
          height: 400px;
        }
        @media only screen and (max-width: 450px) {
          height: 300px;
        }
      }
    }
    .btn-container-mobile {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      @media only screen and (max-width: 768px) {
        display: flex;
      }
      .btn-mobile {
        margin-top: 20px;
        color: $red;
        font-size: 20px;
        &:hover {
          color: $dark-blue;
        }
      }
    }
  }

  // .right-container .image-wrapper .img:hover {
  //   transform: scale(1.1);
    
  // }

  .left-container .btn-container .btn:hover {
    color: $dark-blue;
  }

}
