@import '../../constants/ColorStyles.scss';

.archive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 80%;
    .title {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 50px;
      font-weight: 700;
      color: $dark-blue;
    }
    .subtitle {
      text-align: start;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
      color: $red;
    }
    .data-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      width: 100%;
      .single-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px;
        width: 23%;
        min-width: 250px;
        @media only screen and (max-width: 1370px) {
          width: 31%;
        }
        @media only screen and (max-width: 1100px) {
          width: 46%;
          min-width: none !important;
        }
        @media only screen and (max-width: 700px) {
          width: 100%;
          min-width: none !important;
        }
        .image-wrapper {
          width: 100%;
          height: 200px;
          overflow: hidden;
          .img {
            margin-top: -1px;
            margin-left: -1px;
            width: 101%;
            height: 101%;
            cursor: pointer;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
            &:hover {
              width: 101%;
              transform: scale(1.1);
              
            }
          }
        }
        .single-box-title {
          text-align: start;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 20px;
          height: 50px;
          font-weight: 700;
          color: $red;
        }
        .single-box-text {
          text-align: justify;
          text-justify: inter-word;
          width: 100%;
          max-width: 35rem;
          margin-top: 0px;
          color: $dark-blue;
          height: 160px;
        }
        .btn-cotaniner {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          .btn {
            color: $red;
            &:hover {
              color: $dark-blue;
            }
          }
        }
      }
    }
  }
}
