@import '../../constants/ColorStyles.scss';

.organizer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //   margin-bottom: 40px;
  .container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
    .title {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 50px;
      font-weight: 700;
      color: $dark-blue;
      @media only screen and (max-width: 768px) {
        font-size: 30px;
        margin-top: 10px;
      }
    }
    .subtitle {
      text-align: start;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
      color: $red;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        margin-top: 0px;
      }
    }
    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .img {
        cursor: pointer;
        max-width: 100%;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      }
    }
    .img-box {
      margin-top: 35px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .img {
        cursor: pointer;
        width: 350px;
        height: 180px;
        @media only screen and (max-width: 450px) {
          width: 200px;
          height: 100px;
        }
      }
      .img-second {
        cursor: pointer;
        width: 300px;
        height: 100px;
        @media only screen and (max-width: 450px) {
          width: 200px;
          height: 80px;
        }
      }
    }
    .img-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      max-width: 1300px;
      padding-left: 80px;
      @media only screen and (max-width: 992px) {
        padding-left: 0px;
      }
      .img-list-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        min-width: 220px;
        padding: 10px;
        .img {
          cursor: pointer;
          width: 200px;
          height: 100px;
          margin-top: 30px;
          margin-right: 20px;
        }
      }
    }
  }
}
