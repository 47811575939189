@import '../../constants/ColorStyles.scss';

.add-new-accommodation {
  width: 100%;
  margin-top: -8px;
  border-top: 8px solid $dark-blue;
  padding-top: 10px;
  margin-bottom: 10px;
  .inner-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    width: 100%;
    @media only screen and (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 1040px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .header-accommodation {
      text-align: start;
      grid-column: 1 / 5;
      width: 100%;
      @media only screen and (max-width: 700px) {
        grid-column: 1 / 5;
        // grid-row: 4;
      }
      .accommodation-number {
        font-size: 30px;
        font-weight: 700;
        margin-top: 0px;
        margin-bottom: 10px;
        color: $dark-blue;
      }
    }
    .input-note {
      text-align: start;
      margin-top: 0px;
      font-size: 13px;
      color: rgb(185, 185, 185);
      margin-top: -25px;
      text-decoration: none;
      .span-wrapper {
        text-decoration: none;
      }
      span {
        color: $red;
      }
    }
    .selection {
      grid-column: 1;
      @media only screen and (max-width: 1300px) {
        grid-column: 1 / 3;
        grid-row: 2;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 1 / 5;
        grid-row: 2;
      }
    }
    .field-box-first {
      display: flex;
      justify-content: space-between;
      width: 100%;
      grid-column: 2;
      .form-input-first {
        width: 66.5%;
      }
      .form-input-second {
        width: 30%;
      }
      .form-input-second-disabled {
        pointer-events: none;
        width: 30%;
      }
      @media only screen and (max-width: 1300px) {
        grid-column: 3 / 5;
        grid-row: 2;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 1 / 5;
        grid-row: 3;
        .form-input-first {
          width: 69%;
        }
        .form-input-second {
          width: 29%;
        }
        .form-input-second-disabled {
          pointer-events: none;
          width: 29%;
        }
      }
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        width: 100%;
        .form-input-first {
          width: 100%;
        }
        .form-input-second {
          width: 100%;
        }
        .form-input-second-disabled {
          pointer-events: none;
          width: 100%;
        }
      }
    }

    .field-box-second {
      display: flex;
      justify-content: space-between;
      width: 100%;
      grid-column: 3 / span 2;
      .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels{
        background-color: red;
      }
      .form-input-first {
        width: 66.5%;
      }
      .form-input-second {
        pointer-events: none;
        width: 30%;
      }
      @media only screen and (max-width: 1300px) {
        grid-column: 1 / 3;
        grid-row: 3;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 1 / 5;
        grid-row: 4;
        .form-input-first {
          width: 69%;
        }
        .form-input-second {
          width: 29%;
        }
      }
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        .form-input-first {
          width: 100%;
        }
        .form-input-second {
          width: 100%;
        }
      }
    }

    .field-box-third {
      display: flex;
      justify-content: space-between;
      width: 100%;
      grid-row: 3;
      .form-input-first {
        pointer-events: none;
        width: 66.5%;
      }
      .form-input-second {
        padding-top: 4px;
        line-height: 12px;
        pointer-events: none;
        width: 30%;
        margin: 0;
        p {
          visibility: hidden;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $red;
          border-color: $red;
        }
        .ant-checkbox-checked:after {
          border: none;
        }
      }
      @media only screen and (max-width: 1300px) {
        grid-column: 3 / 5;
        grid-row: 3;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 1 / 5;
        grid-row: 5;
        .form-input-first {
          width: 69%;
        }
        .form-input-second {
          width: 29%;
        }
      }
      @media only screen and (max-width: 700px) {
        flex-direction: column;
        .form-input-first {
          width: 100%;
        }
        .form-input-second {
          width: 100%;
        }
      }
    }

    .field-box-four {
      display: flex;
      justify-content: space-between;
      width: 100%;
      grid-row: 3;
      grid-column: 2 / span 3;
      @media only screen and (max-width: 1300px) {
        grid-column: 1/5;
        grid-row: 4;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 1 / 5;
        grid-row: 6;
      }
      .form-input-first {
        pointer-events: none;
        width: 97%;
        margin-right: 4px;
      }
      .btn-container {
        width: 100%;
        min-width: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
        .btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 4px;
          border-radius: 3px;
          width: 30px;
          height: 30px;
          background-color: $red;
        }
      }
    }
    .participants-list {
      display: flex;
      flex-direction: column;
      grid-column: 1/5;
    }
  }

  input {
    color: $dark-blue;
  }
  .ant-picker-range {
    width: 100%;
  }

  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    color: $dark-blue;
  }

  .ant-picker .ant-picker-input > input {
    color: $dark-blue;
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-input:hover {
    border-color: #95a3b3;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #95a3b3;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    border-color: #95a3b3;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #95a3b3;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #95a3b3;
  }
  .ant-switch.ant-switch-checked {
    background: $red;
  }
  .ant-switch:hover:not(.ant-switch-disabled) {
    background: #4f83cc;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: $dark-blue;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #95a3b3;
  }
  .ant-switch.ant-switch-checked {
    background: $red;
  }
}
.btn-primary {
  background-color: $red;
  &:hover {
    background-color: $white !important;
    color: $red !important;
    border: 1px solid $red;
  }
}

@media(max-width: 630px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
}

.ant-picker-dropdown .ant-picker-cell {
  font-weight: bold !important;
  background-color: rgba(0, 0, 0, 0.25); 
}

.ant-picker-dropdown .ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  font-weight: 400 !important;
  pointer-events: none !important;
  background-color: white;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: rgba(0, 0, 0, 0);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: rgba(0, 0, 0, 0) !important;
}
