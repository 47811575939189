@import '../../constants/ColorStyles.scss';

.new-participant {
  width: 100%;
  border-top: 2px solid $dark-blue;
  margin-top: -2px;
  .inner-container-participant {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    width: 100%;
    @media only screen and (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 1040px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .header {
      display: flex;
      align-items: center;
      width: 100%;
      grid-column: 1/5;
      @media only screen and (max-width: 1400px) {
        grid-column: 1 / 4;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 1 / 3;
      }
      @media only screen and (max-width: 700px) {
        grid-column: 1 / 2;
      }
      .participant-number {
        font-size: 22px;
        font-weight: 700;
        margin-top: 0px;
        margin-bottom: 0px;
        color: $dark-blue;
      }
    }
    .btn-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-column: 2 / 5;
      .btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        // margin-right: 10px;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        background-color: $red;
      }
      @media only screen and (max-width: 1400px) {
        grid-column: 1 / 4;
      }
      @media only screen and (max-width: 1040px) {
        grid-column: 2 / 3;
      }
      @media only screen and (max-width: 700px) {
        grid-column: 1 / 2;
      }
    }
  }
  .phone-input-container {
    position: relative;
    .phone-input {
      box-sizing: border-box;
      margin: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      width: 100%;
      min-width: 0;
      height: 32.5px;
      padding: 4px 5px;
      color: #000000d9;
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      transition: all 0.3s;
      input {
        border: none;
        outline: none;
      }
      &:hover {
        border: 1px solid $light-gray;
      }
      .PhoneInputCountry {
        margin-top: -5px;
        margin-bottom: -5px;
        padding-right: 5px;
        border-right: 1px solid #d9d9d9;
      }
    }

    .phone-input-error {
      box-sizing: border-box;
      margin: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      width: 100%;
      height: 32.5px;
      min-width: 0;
      padding: 4px 5px;
      color: #000000d9;
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ff7875;
      border-radius: 6px;
      transition: all 0.3s;
      &:hover {
        border: 1px solid $light-gray;
        .PhoneInputCountry {
          border-right: 1px solid $light-gray;
        }
      }
      input {
        border: none;
        outline: none;
      }
      .PhoneInputCountry {
        margin-top: -5px;
        margin-bottom: -5px;
        padding-right: 5px;
        border-right: 1px solid #ff7875;
      }
    }

    .phone-description {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 270px;
      width: 100%;   
      top: 54%;
      color: rgb(125, 125, 125);
    }
    
    .phone-description-no-visible {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 270px;
      top: 54%;
      width: 100%;      
      color: #ff4d4f;
    }
  }

  input {
    color: $dark-blue;
  }

  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    color: $dark-blue;
  }

  .ant-picker .ant-picker-input > input {
    color: $dark-blue;
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-input:hover {
    border-color: #95a3b3;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #95a3b3;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    border-color: #95a3b3;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #95a3b3;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #95a3b3;
  }
  .ant-switch.ant-switch-checked {
    background: $red;
  }
  .ant-switch:hover:not(.ant-switch-disabled) {
    background: #4f83cc;
  }
}
