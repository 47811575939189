@import '../../constants/ColorStyles.scss';

.main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $light-blue;
  .title {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 50px;
    font-weight: 700;
    color: $dark-blue;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }
  .text {
    margin-top: 10px;
    width: 80%;
    text-align: justify;
    text-justify: inter-word;
    color: $dark-blue;
    @media only screen and (max-width: 450px) {
      width: 95%;
    }
  }
  .data-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    min-width: 600px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      min-width: 0px;
      justify-content: center;
    }
    @media only screen and (max-width: 575px) {
      width: 100%;
      min-width: 0px;
      flex-direction: column;
      justify-content: center;
    }
    .single-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &:first-child {
        min-width: 190px;
        @media only screen and (max-width: 768px) {
          min-width: 120px;
        }

      }
      &:nth-child(2){
        min-width: 150px;
        @media only screen and (max-width: 768px) {
          min-width: 100px;
        }
      }
      min-width: 120px;
      @media only screen and (max-width: 768px) {
        min-width: 90px;
        margin-right: 10px;
      }
      .number {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 60px;
        font-weight: 600;
        color: $dark-blue;
        @media only screen and (max-width: 768px) {
          font-size: 40px;
        }
      }
      .single-box-text {
        margin-top: 10px;
        font-size: 19px;
        color: $red;
        @media only screen and (max-width: 450px) {
          font-size: 22px;
          margin-top: 0px;
        }
      }
    }
  }
}
