@import '../../constants/ColorStyles.scss';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright&family=Ubuntu:wght@300;500&display=swap');

.mobile-header {
  display: none;
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
  .inner-container {
    // background: $red-blue;
    width: 100%;
    height: 100%;
    // opacity: 0.87;
    .navigation {
      display: flex;
      height: 60px;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0px;
      .image {
        margin-left: 20px;
        height: 70%;
        margin-top: 10px;
        width: 100px;
      }
      .burger-menu {
        margin-right: 20px;
        margin-top: 4px;
      }
    }
    @keyframes fadeInUp {
      from {
        transform: translate3d(0, 40px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    @-webkit-keyframes fadeInUp {
      from {
        transform: translate3d(0, 40px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .animatedFadeInUp {
      opacity: 0;
    }

    .fadeInUp {
      opacity: 0;
      animation-name: fadeInUp;
      -webkit-animation-name: fadeInUp;
    }

    .context {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .img-first {
          width: 100%;
          height: auto;
          max-height: 470px;
          @media only screen and (max-width: 500px) {
            display: none;
          }
        }
        .img-second {
          width: 100%;
          height: auto;
          display: none;
          @media only screen and (max-width: 500px) {
            display: flex;
          }
        }
      }
      .title {
        position: absolute;
        bottom: 60%;
        text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.75);
        margin-top: 0px;
        font-size: 30px;
        color: $white;
        font-weight: 700;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        @media only screen and (max-width: 600px) {
          font-size: 25px;
        }
        @media only screen and (max-width: 485px) {
          bottom: 70%;
        }
      }
      .sub-title {
        text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.75);
        width: 80%;
        margin-top: 0px;
        font-size: 24px;
        color: $white;
        margin-bottom: 40px;
        font-weight: 400;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
      }
      .sub-title-first {
        position: absolute;
        bottom: 55%;
        text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.75);
        font-family: 'Cormorant Upright', serif;
        width: 80%;
        margin-top: 0px;
        font-size: 36px;
        color: $white;
        margin-bottom: 0px;
        font-weight: 400;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        @media only screen and (max-width: 768px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 600px) {
          font-size: 25px;
        }
        @media only screen and (max-width: 485px) {
          bottom: 68%;
        }
        @media only screen and (max-width: 331px) {
          font-size: 18px;
        }
      }
      .sub-title-second-first {
        font-family: 'Cormorant Upright', serif;
        width: 80%;
        margin-top: 0px;
        font-size: 36px;
        color: $white;
        margin-bottom: 20px;
        font-weight: 400;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
      }
    }
  }
}
