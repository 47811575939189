@import '../../constants/ColorStyles.scss';

.gallery {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  .title {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 50px;
    font-weight: 700;
    color: $dark-blue;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
      margin-top: 10px;
    }
  }
  .subtitle {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 700;
    color: $red;
    @media only screen and (max-width: 768px) {
      font-size: 17px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }
  .img-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    .image-wrapper {
      display: flex;
      width: 23.2%;
      min-width: 300px;
      margin-right: 10px;
      margin-top: 10px;
      overflow: hidden;
      @media only screen and (max-width: 1568px) {
        width: 32%;
      }
      @media only screen and (max-width: 1190px) {
        width: 48%;
        min-width: 150px;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
      }
      .img {
        margin-top: -1px;
        margin-left: -1px;
        width: 101%;
        height: 101%;
        transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        &:hover {
          max-height: 101%;
          transform: scale(1.1);
        }
      }
    }
  }
  .btn-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      color: $red;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
      &:hover {
        color: $dark-blue;
      }
    }
  }
}
