@import '../../constants/ColorStyles.scss';

.list-participants {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    @media only screen and (max-width: 768px) {
      padding-bottom: 10px;
    }
    .title {
      margin-top: 10px;
      font-size: 50px;
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        font-size: 30px;
        margin-top: 20px;
      }
    }
    .data-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
      .single-item {
        width: 20%;
        text-align: left;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 20px;
        @media only screen and (max-width: 992px) {
          width: 30%;
        }
        @media only screen and (max-width: 768px) {
          width: 40%;
        }
        @media only screen and (max-width: 500px) {
          width: 100%;
        }
        .image {
          width: 100%;
          transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
          &:hover {
            transform: scale(1.1);
            width: 100%;
          }
        }
        .single-item-title {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          color: $red;
          margin-bottom: 0px;
        }
        .name {
          text-align: center;
          margin-top: 0px;
          color: $white;
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .btn {
        background-color: transparent;
        color: $red;
      }
      .btn-participants{
        background-color: transparent;
        text-decoration: underline;
        cursor: pointer;
        font-size: 15px;
        border: none;
        color: $red;
        &:hover {
          color: $dark-blue;
        }
      }
    }
  }
}
