@import '../../constants/ColorStyles.scss';

.main-header {
  // height: 630px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .inner-container {
    width: 100%;
    height: 100%;
    .navigation {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 80px;
      @media only screen and (max-width: 992px) {
        justify-content: center !important;
      }
    }

    @keyframes fadeInUp {
      from {
        transform: translate3d(0, 40px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    @-webkit-keyframes fadeInUp {
      from {
        transform: translate3d(0, 40px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .animatedFadeInUp {
      opacity: 0;
    }

    .fadeInUp {
      opacity: 0;
      animation-name: fadeInUp;
      -webkit-animation-name: fadeInUp;
    }

    .context {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .img-first {
          width: 100%;
          height: auto;
          min-height: 400px;
          @media only screen and (max-width: 1100px) {
            display: none;
          }
        }
        .img-second {
          width: 100%;
          height: auto;
          display: none;
          @media only screen and (max-width: 1100px) {
            display: flex;
          }
        }
      }
      .image {
        writing-mode: inherit;
        transform: rotate(0deg);
        width: 250px;
        margin-bottom: 30px;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
      }
      .title {
        position: absolute;
        bottom: 40%;
        text-shadow: -5px 5px 20px rgba(0, 0, 0, 1);
        margin-top: 0px;
        width: 100%;
        min-width: 700px !important;
        font-size: 50px;
        font-weight: 700;
        color: $white;
        margin-bottom: 20px;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        @media only screen and (max-width: 1100px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 840px) {
          font-size: 35px;
        }
      }
      .subtitle {
        text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.75);
        margin-top: 0px;
        width: 50%;
        min-width: 700px !important;
        font-size: 30px;
        font-weight: 400;
        color: $white;
        margin-bottom: 30px;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
      }
      .subtitle-first {
        position: absolute;
        bottom: 30%;
        text-shadow: -5px 5px 20px rgba(0, 0, 0, 1);
        font-family: 'Gabriola';
        margin-top: -10px;
        width: 40%;
        min-width: 700px !important;
        font-size: 45px;
        font-weight: 400;
        color: $white;
        margin-bottom: 0px;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        @media only screen and (max-width: 1100px) {
          bottom: 32%;
          font-size: 35px;
        }
        @media only screen and (max-width: 840px) {
          font-size: 30px;
        }
      }
      .subtitle-second {
        font-family: 'Gabriola';
        margin-top: -20px;
        width: 40%s;
        min-width: 700px !important;
        font-size: 45px;
        font-weight: 400;
        color: $white;
        margin-bottom: 10px;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
      }
      .button-container {
        margin-top: 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 330px;
        height: 50px;
        animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 140px;
          border-radius: 7px;
          color: $white;
          text-decoration: none;
        }
        .blue {
          padding-top: 7px;
          padding-bottom: 7px;
          border: 2px solid $dark-blue;
          background-color: $dark-blue;
          &:hover {
            background-color: transparent;
            border: 2px solid $white;
            transition: 0.2s ease-in-out;
          }
        }
        .white {
          padding-top: 2px;
          padding-bottom: 2px;
          border: 2px solid $white;
          background-color: transparent;
          &:hover {
            background-color: $dark-blue;
            border: 2px solid $dark-blue;
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
