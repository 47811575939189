@import '../../constants/ColorStyles.scss';

.agenda-navigation {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .title {
    text-align: start;
    width: 100%;
    margin-top: 0px;
    font-size: 50px;
    font-weight: 700;
    color: $dark-blue;
    margin-bottom: 0px;
  }
  .sub-title {
    width: 100%;
    text-align: start;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 700;
    color: $red;
  }
  .description {
    width: 100%;
    display: flex;
    justify-content: center;
    .description-title {
      color: $dark-blue;
      font-size: 25px;
      font-weight: 500;
    }
  }
  .navigation-container {
    //margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    // border: 2px solid black;
    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 90px;
      .single-tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 24%;
        height: 85px;
        .tab {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 60px;
          width: 100%;
          border: 2px solid $dark-blue;
          .tab-title {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 700;
            color: $dark-blue;
            margin-bottom: 0px;
          }
          .tab-date {
            margin-top: 0px;
            color: $dark-blue;
          }
        }
        .activeTab {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 60px;
          width: 100%;
          background-color: $dark-blue;
          border: 2px solid $dark-blue;
          .activeTab-title {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 700;
            color: $white;
            margin-bottom: 0px;
          }
          .activeTab-date {
            margin-top: 0px;
            color: $white;
          }
        }
        .triangle {
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid $dark-blue;
        }
      }
    }
    .blue-text {
      color: $dark-blue;
    }
    .data-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .data-title {
        display: flex;
        align-items: center;
        text-align: start;
        width: 100%;
        font-size: 20px;
        padding-left: 5px;
        font-weight: 700;
        color: $white;
        background: $red-blue;
        margin-top: 2px;
        margin-bottom: 2px;
        .data-title-text {
          margin-left: 10px;
          p {
            margin-top: 7px;
            margin-bottom: 7px;
          }
        }
        @media only screen and (max-width: 992px) {
          background: $red-blue;
        }
      }
      .data-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        .left-container {
          display: flex;
          align-items: flex-start;
          width: 100%;
          .img {
            height: 15px;
            margin-top: 10px;
            margin-right: 5px;
            margin-left: 5px;
          }
          .time {
            text-align: center;
            color: $dark-blue;
            margin-right: 10px;
            font-size: 17px;
            margin-top: 7px;
            min-width: 45px;
          }
          .text {
            text-align: start;
            color: $dark-blue;
            margin-right: 10px;
            font-size: 17px;
            p {
              margin-top: 7px;
              margin-bottom: 7px;
            }
          }
          .company {
            font-weight: 700;
            color: $dark-blue;
            font-size: 19px;
          }
        }
        .right-container {
          width: 15%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding-right: 25px;
          @media only screen and (max-width: 992px) {
            padding-right: 50px;
          }
          .image {
            margin-right: 10px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn {
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      // font-weight: 400;
      font-size: 17px;
      color: $red;
      border: none;
    }
    .agenda-container {
      display: none;
    }
  }
}
