@import '../../constants/ColorStyles.scss';

.sign-up-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    .title {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 50px;
      font-weight: 700;
      color: $dark-blue;
    }
    .subtitle {
      margin-top: 20px;
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
      color: $red;
    }
    .text {
      margin-top: 25px;
      margin-bottom: 30px;
      text-align: justify;
      text-justify: inter-word;
      color: $dark-blue;
    }
    .banner-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 300px;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
      }
      .banner-wrapper-left {
        width: 49%;
        height: 100%;
        overflow: hidden;
        text-decoration: none;
        @media only screen and (max-width: 768px) {
          width: 100%;
          height: 200px;
        }
        .left-banner {
          cursor: pointer;
          width: 100%;
          height: 100%;
          background-image: url('../../assets/sign-up-banner-left.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
          &:hover {
            transform: scale(1.1);
          }
          .left-banner-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $red-blue;
            width: 100%;
            height: 100%;
            opacity: 0.87;
            .title {
              width: 20rem;
              font-size: 40px;
              color: $white;
              font-weight: 700;
              margin-left: 20px;
              margin-right: 20px;
              @media only screen and (max-width: 768px) {
                width: auto;
                font-size: 40px;
              }
              @media only screen and (max-width: 450px) {
                font-size: 30px;
              }
            }
          }
        }
      }
      .banner-wrapper-right {
        width: 49%;
        height: 100%;
        overflow: hidden;
        text-decoration: none;
        @media only screen and (max-width: 768px) {
          width: 100%;
          height: 200px;
          margin-top: 20px;
        }
        .right-banner {
          cursor: pointer;
          width: 100%;
          height: 100%;
          background-image: url('../../assets/sign-up-banner-right.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
          &:hover {
            transform: scale(1.1);
          }
          .right-banner-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $red-blue;
            width: 100%;
            height: 100%;
            opacity: 0.87;
            .title {
              width: 30rem;
              font-size: 40px;
              color: $white;
              font-weight: 700;
              margin-left: 20px;
              margin-right: 20px;
              @media only screen and (max-width: 768px) {
                width: auto;
                font-size: 40px;
              }
              @media only screen and (max-width: 450px) {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }
}
