.header {
  height: 80px;
  width: 100%;
  background: white;
  .border {
    border-bottom: 2px solid #C7363D;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .inner-container {
    width: 100%;
    height: 100%;
    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }
      .image-container {
        margin-left: 10%;
        .image {
          width: 150px;
          height: 60px;

        }
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
    }
  }
}
