$dark-blue: #0D4077;
$light-blue: #DCE6F5;
$white: #FFFFFF;
$red: #C7363D;
$light-gray: #95A3B3;
$red-blue:  linear-gradient(
    90deg,
    rgba(199, 54, 61, 1) 0%,
    rgba(13, 64, 119, 1) 100%,
    rgba(13, 64, 119, 1) 100%
  )