@import '../../constants/ColorStyles.scss';

.legal-entity {
  width: 100%;
  .box {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
    .btn-save-application {
      width: 140px;
      background-color: $red;
      &:hover {
        background-color: $white;
        color: $red;
        border: 1px solid $red;
      }
      &:nth-child(2) {
        margin-right: 20px;
        @media only screen and (max-width: 700px) {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .btn-save-form {
      background-color: $red;
      &:hover {
        background-color: $white;
        color: $red;
        border: 1px solid $red;
      }
    }
    .price-box {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @media only screen and (max-width: 700px) {
        margin-bottom: 10px;
      }
      .price-text {
        margin-right: 5px;
        margin-bottom: 5px;
        color: $dark-blue;
        text-align: right;
        @media only screen and (max-width: 360px) {
          margin-top: 0px;
          margin-bottom: -3px;
        }
      }
      .price-input {
        margin-right: 20px;
        width: 120px;
        text-align: left;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 5px;
        border-radius: 7px;
        border: 1px solid $red;
        @media only screen and (max-width: 700px) {
          margin-right: 0px;
        }
      }
    }
  }
  .accordion {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .accordion-item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: $dark-blue;
    }
    .accordion-item-disabled {
      cursor: not-allowed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: rgb(185, 185, 185);
    }
    .header-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      .accordion-header {
        font-size: 22px;
        font-weight: 400;
        user-select: none;
        color: $white;
        @media only screen and (max-width: 450px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 370px) {
          font-size: 15px;
          text-align: start;
          margin-right: 10px;
        }
      }
    }
  }
  .accordion-body {
    .accordion-body-inner {
      padding-top: 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      // grid-gap: 1rem;
      column-gap: 1rem;
      width: 100%;
      @media only screen and (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (max-width: 1040px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .add-new-btn {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    grid-column: 1/5;
    .ant-btn {
      background-color: $red;
      &:hover {
        background-color: $white;
        color: $red;
        border: 1px solid $red;
      }
    }
  }
  .ant-space-item {
    width: 100%;
  }
  .list-of-data {
    display: flex;
    flex-direction: column;
    grid-column: 1/5;
    margin-bottom: 20px;
  }
  .checkbox-group {
    display: flex;
    align-items: flex-end;
    .checkbox {
      margin-top: 20px;
      .checkbox-text {
        text-align: start;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $red;
        border-color: $red;
      }
      .ant-checkbox-checked:after {
        border: none;
      }
    }
  }
  .close {
    display: none;
  }
  .additional-fields {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 20px;
    .btn-save {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 31px;
      background-color: $red;
      border: 2px solid $red;
      color: $white;
      margin-left: 20px;
      border-radius: 7px;
      &:hover {
        background-color: transparent;
        color: $red;
        border: 2px solid $red;
      }
    }
  }

  .text-blue{
    color: $dark-blue;
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Works for Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    text-align: start;
  }

  .ant-form-vertical .ant-form-item-label > label {
    color: $dark-blue;
  }

  label {
    color: $dark-blue;
  }

  input {
    color: $dark-blue;
  }
  .ant-picker-range {
    width: 100%;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: $dark-blue;
  }

  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border-color: #95a3b3;
  }

  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    color: $dark-blue;
  }

  .ant-picker .ant-picker-input > input {
    color: $dark-blue;
  }

  .ant-picker-range {
    width: 100%;
  }
  .ant-input:hover {
    border-color: #95a3b3;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #95a3b3;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    border-color: #95a3b3;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #95a3b3;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #95a3b3;
  }
  .ant-switch.ant-switch-checked {
    background: $red;
  }
  .ant-switch:hover:not(.ant-switch-disabled) {
    background: #4f83cc;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: $dark-blue;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #95a3b3;
  }
}
