@import '../../constants/ColorStyles.scss';

.footer {
  display: flex;
  justify-content: space-between;
  background: $red-blue;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  @media only screen and (max-width: 992px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer-container-left {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    height: 100%;
    @media only screen and (max-width: 992px) {
      width: 100%;
      justify-content: center;
    }
    .inner-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 80%;
      .title {
        color: $white;
        font-weight: 700;
        @media only screen and (max-width: 992px) {
          margin-top: 0px;
          font-size: 25px;
        }
      }
      .input {
        border-radius: 7px;
        margin-top: 15px;
        height: 40px;
        width: 92%;
        padding-left: 10px;
        border: none;
        @media only screen and (max-width: 992px) {
          width: 98%;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
      .message {
        padding-bottom: 60px;
        @media only screen and (max-width: 992px) {
          padding-bottom: 100px;
        }
      }
      .text {
        text-align: justify;
        text-justify: inter-word;
        margin-top: 8px;
        width: 94%;
        color: $white;
        @media only screen and (max-width: 992px) {
          color: $white;
        }
      }
      .btn-container {
        display: flex;
        justify-content: flex-end;
        width: 94%;
        margin-top: 50px;
        @media only screen and (max-width: 992px) {
          width: 100%;
        }
        .btn {
          font-family: Montserrat;
          font-weight: 400;
          cursor: pointer;
          background-color: $red;
          font-size: 16px;
          border-radius: 7px;
          width: 120px;
          height: 35px;
          border: none;
          color: $white;
          &:hover {
            background-color: transparent;
            border: 2px solid $white;
          }
        }
      }
    }
  }
  .footer-container-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    height: 100%;
    @media only screen and (max-width: 992px) {
      width: 80%;
    }
    .column {
      line-height: 7px;
      text-align: left;
      .column-white {
        color: $white;
        @media only screen and (max-width: 992px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 400px) {
          font-size: 16px;
          line-height: 17px;
        }
      }
      .column-red {
        font-weight: 700;
        color: $red;
        @media only screen and (max-width: 992px) {
          font-size: 18px;
        }
      }
    }
    .title {
      user-select: none;
      pointer-events: none;
      font-weight: 700;
      color: transparent;
      @media only screen and (max-width: 992px) {
        font-size: 25px;
      }
    }
  }
}

.copyright-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  @media only screen and (max-width: 660px) {
    height: 40px;
    line-height: 17px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 15px;
    margin-top: 4px;
  }
  @media only screen and (max-width: 360px) {
    height: 50px;
    line-height: 17px;
  }
  .copyright {
    color: $dark-blue;
    font-weight: 450;
    a {
      color: $red;
      font-weight: 450;
    }
  }
}
