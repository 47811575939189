@import '../../constants/ColorStyles.scss';

.participation-mobile-app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .left-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 300px;
    width: 50%;
    background-color: $dark-blue;
    @media only screen and (max-width: 768px) {
      width: 100%;
      min-height: 300px;
      justify-content: center;
    }
    .inner-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 72%;
      padding-right: 8%;
      @media only screen and (max-width: 768px) {
        width: 90%;
        padding-left: 35px;
        padding-right: 35px;
      }
      .title {
        text-align: start;
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 50px;
        font-weight: 700;
        color: $white;
        @media only screen and (max-width: 1150px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 992px) {
          font-size: 25px;
        }
      }
      .text {
        text-align: start;
        margin-top: 0px;
        color: $white;
      }
      .btn-container {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 20px;
        .btn {
          text-decoration: none;
          cursor: pointer;
          width: 140px;
          padding-top: 5px;
          padding-bottom: 7px;
          border-radius: 7px;
          color: $white;
          background-color: $red;
          border: 2px solid $red;
          &:hover {
            background-color: transparent;
            border: 2px solid $white;
          }
          @media only screen and (max-width: 550px) {
            width: 100%;
          }
        }
      }
    }
  }
  .right-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 300px;
    width: 50%;
    background-color: $red;
    @media only screen and (max-width: 768px) {
      width: 100%;
      min-height: 300px;
      justify-content: center;
    }
    .inner-container {
        display: flex;
        width: 76%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        @media only screen and (max-width: 768px) {
          width: 90%;
          padding-left: 35px;
          padding-right: 35px;
        }
      .title {
        text-align: start;
        margin-top: 19px;
        margin-bottom: 20px;
        font-size: 50px;
        font-weight: 700;
        color: $white;
        @media only screen and (max-width: 1150px) {
          font-size: 40px;
        }
        @media only screen and (max-width: 992px) {
          font-size: 25px;
        }
      }
      .text {
        text-align: justify;
        text-justify: inter-word;
        width: 100%;
        max-width: 35rem;
        margin-top: 0px;
        color: $white;
      }
      .btn-container {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 20px;
        .img {
          cursor: pointer;
          width: 140px;
        }
      }
    }
  }
}
