@import '../../constants/ColorStyles.scss';
.time-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 10px;
    .title {
      margin-top: 15px;
      width: 80%;
      min-width: 700px;
      font-size: 50px;
      font-weight: 700;
      color: $red;
      margin-bottom: 0px;
      @media only screen and (max-width: 768px) {
        font-size: 30px;
        min-width: 0px;
        width: 100%;
      }
      @media only screen and (max-width: 450px) {
        font-size: 20px;
      }
    }
    .title-second{
      margin-top: 15px;
      width: 80%;
      min-width: 700px;
      font-size: 36px;
      font-weight: 700;
      color: $dark-blue;
      margin-bottom: 0px;
      @media only screen and (max-width: 768px) {
        font-size: 30px;
        min-width: 0px;
        width: 100%;
      }
      @media only screen and (max-width: 450px) {
        font-size: 15px;
      }
    }
    .time-counter {
      display: flex;
      justify-content: space-between;
      width: 30%;
      min-width: 400px;
      margin-bottom: 15px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        min-width: 0px;
      }
      .row {
        width: 20%;
        display: flex;
        flex-direction: column;
        .time {
          margin-top: 10px;
          font-size: 36px;
          font-weight: 600;
          color: $dark-blue;
          margin-bottom: 10px;
          @media only screen and (max-width: 768px) {
            font-size: 30px;
            margin-bottom: 0px;
          }
          @media only screen and (max-width: 450px) {
            font-size: 15px;
            margin-bottom: 0px;
          }
        }
        .label {
          margin-top: 0px;
          font-size: 17px;
          color: $dark-blue;
          @media only screen and (max-width: 450px) {
            font-size: 10px;
          }
        }
      }
    }
  }