@import '../../constants/ColorStyles.scss';

.navigation-inner-container {
  display: flex;
  max-width: 55%;
  margin-right: 100px;
  @media only screen and (max-width: 992px) {
    margin-right: 0px;
  }
  .nav-item {
    position: relative;
    width: 90px;
    height: 20px;
    margin-right: 9px;
    text-decoration: none;
    z-index: 1;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: $red;
    background-image: linear-gradient(to right, $red, $red 50%, $white 50%);
    background-size: 200% 100%;
    background-position: -100%;
    transition: all 0.3s ease-in-out;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    &:before {
      display: block;
      content: '';
      width: 0;
      height: 3px;
      bottom: 5px;
      left: 0;
      bottom: -3px;
      z-index: 0;
      position: absolute;
      background: $red;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      background-position: 0%;
      &:before {
        width: 100%;
      }
    }
  }
  .activeClass {
    font-family: 'Montserrat';
    font-style: normal;
    color: $red;
    width: 90px;
    border-bottom-style: solid;
    background-image: linear-gradient(to right, $red, $red 50%, $red 50%);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
